<template>
  <div class="scaleable-wrapper" id="scaleable-wrapper">
    <div
      class="fixed-aspect-ratio-container"
      id="fixed-aspect-ratio-container"
      v-bind:style="scale"
    >
      <div class="zoom-container" v-bind:style="currentZoom">
        <svg
          class="svg-container"
          viewbox="0 0 1000 600"
          width="600"
          height="1000"
        >
          <svg v-for="node in nodesToDraw" v-bind:key="node.id">
            <svg v-bind:class="node.nodeType === 'action' ? 'svg-element' : ''">
              <polygon
                @click="nodeClicked(node.id)"
                v-bind:fill="
                  nodeColor(node.id, node.name, node.fill, node.nodeType)
                "
                v-bind:points="node.svgStyles.points"
              />
              <text
                v-if="node.text"
                @click="nodeClicked(node.id)"
                v-bind:font-size="
                  fontSizeCalculator(node.svgStyles.points, node.text)
                "
                text-anchor="middle"
                v-bind:x="
                  node.textTelemetry
                    ? node.textTelemetry.x
                    : textAnchor(node.svgStyles.points, 'x')
                "
                v-bind:y="
                  (node.textTelemetry
                    ? node.textTelemetry.y
                    : textAnchor(node.svgStyles.points, 'y')) + 5
                "
              >
                {{ node.text }}
              </text>
            </svg>
          </svg>
        </svg>
      </div>
    </div>
    <v-speed-dial
      v-model="fab"
      right
      bottom
      style="touch-action: none; margin-bottom: 20px"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" v-bind:color="fabStates[fabState].color" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else>
            {{ fabStates[fabState].icon }}
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green" @click="fabClicked(0)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark small color="indigo" @click="fabClicked(1)">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </v-speed-dial>
    <router-view></router-view>
  </div>
</template>

<script>
import { firebaseApp } from "../firebase/init";
import "firebase/firestore";

export default {
  name: "Buildings",
  data: function () {
    return {
      fab: false,
      fabState: 0,
      fabStates: [
        { icon: "mdi-pencil", color: "green" },
        { icon: "mdi-eye", color: "indigo" },
      ],
      roomCleaningData: [],
    };
  },
  computed: {
    path() {
      return this.$store.state.path;
    },
    bulkSelection() {
      return this.$store.state.buildingBulkSelection;
    },
    selectionAction() {
      return this.$store.state.buildingSelectionAction;
    },
    bulkSelectionRoomType() {
      return this.$store.state.buildingBulkRoomType;
    },
    scale() {
      return this.$store.state.scale;
    },
    nodes() {
      const buildings = require("../json/buildings.json");
      return buildings.buildings;
    },
    currentZoom() {
      var value = null;
      var array = [...this.path.zoom];
      while (value == null && array.length > 0) {
        value = array.pop();
      }
      return value;
    },
    nodesToDraw() {
      var arrayFromNodes = new Array();
      var navigationButtons = new Array();
      var visibleNodes = this.nodesToRender(this.path, this.nodes);
      for (var i = 0; i < visibleNodes.length; i++) {
        if (visibleNodes[i]) {
          arrayFromNodes.push(this.nodes[i]);
          if (
            this.nodes[i].nodeType === "backgroundVisibleZoom" &&
            !this.navigationNodeInPath(this.nodes[i].id)
          ) {
            var temp = { ...this.nodes[i] };
            temp.id += ":";
            temp.fill = "#ffffff00";
            temp.nodeType = "navigation";
            navigationButtons.push(temp);
          }
        }
      }
      return arrayFromNodes.concat(navigationButtons.reverse());
    },
    bulkSelectionAsObject() {
      return this.bulkSelection.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: this.selectionAction === "Finished",
          }),
        {}
      );
    },
    bulkSelectionActiveKeys() {
      return this.bulkSelection.map((item) => item.id);
    },
  },
  methods: {
    fabClicked(fabState) {
      this.fabState = fabState;
    },
    nodesToRender(path, array) {
      var output = new Array();
      for (var i = 0; i < path.id.length; i++) {
        if (path.externallyControlled[i]) {
          for (var k = 0; k < array.length; k++) {
            var splitId = array[k].id.split(".");
            //-----------------------------------check if node has svgPoints - only include if it does, check if node id at path index matches path at index
            output[k] =
              array[k].svgStyles === undefined
                ? false
                : splitId[i] === undefined
                ? output[k]
                : splitId[i] === path.id[i] && splitId[0] === path.id[0];
          }
        }
      }
      return output;
    },
    nodeClicked(id) {
      var pathId = [...this.path.id];
      var zoom = [...this.path.zoom];
      var node;
      for (var checkNode of this.nodesToDraw) {
        if (checkNode.id == id) {
          node = { ...checkNode };
          break;
        }
      }
      if (node.nodeType === "action") {
        if (
          this.fabState === 0 &&
          node.name.indexOf(this.bulkSelectionRoomType) >= 0 &&
          this.bulkSelectionActiveKeys.length > 0
        ) {
          console.log("Id: " + id + ", Node: " + node.name);
          var cleaningDataIndex = this.roomCleaningData
            .map(function (room) {
              return room.id;
            })
            .indexOf(id);
          if (cleaningDataIndex >= 0) {
            var cleaningObject = {
              ...this.roomCleaningData[cleaningDataIndex].clean,
            };
            firebaseApp
              .firestore()
              .collection("rooms")
              .doc(id)
              .update({
                clean: Object.assign(
                  cleaningObject,
                  this.bulkSelectionAsObject
                ),
              });
          } else {
            firebaseApp.firestore().collection("rooms").doc(id).set({
              clean: this.bulkSelectionAsObject,
              id: id,
              name: node.name,
            });
          }
        } else if (this.fabState === 1 || this.fabState === 0) {
          this.$router.push({
            name: "BuildingsDetail",
            params: {
              id: id,
              nodeName: node.name,
              nodeDetailText: node.detailPageText,
            },
          });
        } else {
          console.log(
            'New Action State Added. Please add conditions in "nodeClicked" computed property -> Buildings.vue'
          );
        }
      } else if (node.nodeType === "navigation") {
        var idArray = id.slice(0, -1).split(".");
        var insertIndex = idArray.length - 1;
        pathId[insertIndex] = idArray.pop();
        zoom[insertIndex] = node.zoom;
        this.$store.commit("pathId", pathId);
        this.$store.commit("zoom", zoom);
      }
    },
    nodeColor(id, name, fill, nodeType) {
      if (nodeType === "action") {
        if (this.fabState === 0 || this.fabState === 1) {
          var index = this.roomCleaningData
            .map(function (room) {
              return room.id;
            })
            .indexOf(id);
          var sum = 0;
          if (index !== -1) {
            if (
              name.indexOf(this.bulkSelectionRoomType) >= 0 &&
              this.bulkSelectionActiveKeys.length > 0
            ) {
              for (var selection of this.bulkSelectionActiveKeys) {
                if (this.roomCleaningData[index].clean[selection]) sum += 1;
              }
              return this.colorPercentageProcessor(
                sum,
                this.bulkSelectionActiveKeys.length,
                "#FF0000",
                "#FFFF33",
                "#00FF00"
              );
            } else if (
              this.bulkSelectionActiveKeys.length === 0 ||
              this.fabState === 1
            ) {
              for (var key in this.roomCleaningData[index].clean) {
                if (this.roomCleaningData[index].clean[key]) sum += 1;
              }
              return this.colorPercentageProcessor(
                sum,
                Object.keys(this.roomCleaningData[index].clean).length,
                "#FF0000",
                "#FFFF33",
                "#00FF00"
              );
            } else {
              for (var key2 in this.roomCleaningData[index].clean) {
                if (this.roomCleaningData[index].clean[key2]) sum += 1;
              }
              return this.colorPercentageProcessor(
                sum,
                Object.keys(this.roomCleaningData[index].clean).length,
                "#CF0000",
                "#CFCF33",
                "#00CF00"
              );
            }
          }
          return "#FFFFFF";
        } else {
          console.log(
            'New Fast Action Button State Added. Please add conditions in "nodesToDraw" computed property -> Buildings.vue'
          );
        }
      } else if (nodeType === "navigation") {
        return fill;
      } else {
        return fill;
      }
    },
    colorPercentageProcessor(
      numerator,
      denominator,
      zeroColor,
      midColor,
      completeColor
    ) {
      if (numerator === 0) {
        return zeroColor;
      } else if (numerator === denominator) {
        return completeColor;
      } else {
        return midColor;
      }
    },
    navigationNodeInPath(nodeId) {
      var pathIdArray = [...this.path.id];
      var nodeIdArray = nodeId.split(".");
      for (var i = 0; i < nodeIdArray.length; i++) {
        if (pathIdArray[i] !== nodeIdArray[i]) return false;
      }
      return true;
    },
    textAnchor(points, direction) {
      var splitPoints = points.split(", ");
      var pointsOutput = [];
      for (var i = 0; i < splitPoints.length; i++) {
        var splitHolder = splitPoints[i].split(" ");
        pointsOutput.push(parseInt(splitHolder[direction == "y" ? 1 : 0]));
      }
      var max = Math.max(...pointsOutput);
      var min = Math.min(...pointsOutput);
      var average = (max + min) / 2 || 0;
      return average;
    },
    fontSizeCalculator(points, text) {
      var splitPoints = points.split(", ");
      var yPointsOutput = [];
      var xPointsOutput = [];
      for (var i = 0; i < splitPoints.length; i++) {
        var splitHolder = splitPoints[i].split(" ");
        xPointsOutput.push(parseInt(splitHolder[0]));
        yPointsOutput.push(parseInt(splitHolder[1]));
      }
      var xMax = Math.max(...xPointsOutput);
      var xMin = Math.min(...xPointsOutput);
      var yMax = Math.max(...yPointsOutput);
      var yMin = Math.min(...yPointsOutput);
      var xFontSize = Math.floor((xMax - xMin) / (text.length / 1.6));
      var yFontSize = Math.floor((yMax - yMin) / 2.5);
      if (xFontSize > 12 && yFontSize > 12) return 12;
      if (xFontSize < yFontSize) return xFontSize;
      return yFontSize;
    },
  },
  firestore: {
    roomCleaningData: firebaseApp.firestore().collection("rooms"),
  },
};
</script>

<style scoped>
.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}
.svg-element:active {
  opacity: 50%;
}
.svg-container {
  position: absolute;
}

div.zoom-container {
  position: absolute;
  transition: all 1s;
  transform-origin: center;
}

.fixed-aspect-ratio-container {
  width: 600px;
  height: 1000px;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  overflow: visible;
  touch-action: none;
}

.scaleable-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh - 108px);
  max-height: calc(100vh - 108px);
}
</style>
